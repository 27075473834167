<template>
    <div>
        <!-- make a div of vertically and horizontally align -->
        <div class="d-flex justify-content-center align-items-center flex-column" style="height: calc(100vh - (10/100 * 100vh));">
            <div class="d-flex mb-2">{{ loadingMsg }}</div>
            <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
</template>

<script>
import Swal from "sweetalert2";
import API from "../../utils/API";
import { mapActions, mapGetters } from "vuex";

export default {
    name: 'SelangkahSSO',
    data() {
        return {
            loadingMsg: "Loading..."
        }
    },
    methods: {
        ...mapActions(["signup", "loginWithSelangkahSSO"]),
    },
    async mounted() {
        try {
            this.loadingMsg = 'Checking user...'

            if(!this.$route.query.selangkahID || !this.$route.query.phoneNumber || !this.$route.query.email){
                throw "Bad request."
            }
            const { selangkahID, phoneNumber, email } = this.$route.query

            this.loadingMsg = 'Authenticating ...'
            
            let ssoRes = await API.post('/custom/selangkah-sso-auth', {
                selangkahID,
                phoneNumber,
                email
            })
            if(ssoRes.data.errMsg){
                throw ssoRes.data.errMsg
            }

            // console.log('data : ', ssoRes.data)
            if(ssoRes.data.msg == "user_strapi_not_found"){ //create one first
                this.loadingMsg = 'Creating new user using selangkah ID ...'

                const selangkahUser = ssoRes.data.selangkahUser
                const newUser = await API.authenticate_register({
                    // username: selangkahUser.email.substring(0, selangkahUser.email.indexOf('@')),
                    username: selangkahUser.email,
                    email: selangkahUser.email,
                    password: selangkahUser.email, //for now or temp
                    firstname: selangkahUser.acc_name,
                })
                await API.get(`/custom/user/${newUser.data.user.id}/create-customer-relation?nric=${selangkahUser.ic_no}&fullname=${selangkahUser.acc_name}`)
                // await API.get(`/custom/user/${newUser.data.user.id}/create-customer-relation?fullname=${selangkahUser.firstname}`)
                
                this.loadingMsg = 'Done creating user ...'
                this.loadingMsg = 'Reauthenticating ...'

                ssoRes = await API.post('/custom/selangkah-sso-auth', {
                    selangkahID,
                    phoneNumber,
                    email
                })
            }

            if(ssoRes.data.user && (!ssoRes.data.user.customer || !ssoRes.data.user.customer == null)){
                this.loadingMsg = 'Re-creating user customer data ...'

                await API.get(`/custom/user/${ssoRes.data.user.id}/create-customer-relation?nric=${ssoRes.data.selangkahUser.ic_no}&fullname=${ssoRes.data.selangkahUser.acc_name}`)
                
                this.loadingMsg = 'Done re-creating user customer data ...'
                this.loadingMsg = 'Reauthenticating ...'

                ssoRes = await API.post('/custom/selangkah-sso-auth', {
                    selangkahID,
                    phoneNumber,
                    email
                })
            }

            this.loadingMsg = 'Ready to login ...'

            const redirectAfter = this.$route.query.redirectAfter ? this.$route.query.redirectAfter : '/'

            // console.log('user : ', ssoRes.data.user)

            this.loginWithSelangkahSSO({
                jwtToken: ssoRes.data.jwtToken,
                user: ssoRes.data.user,
                selangkahData: {
                    selangkahID,
                    phoneNumber,
                    email
                },
                redirectAfter
            });
            
        } catch (errMsg) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                html: `<p class='mb-0'>${errMsg.data? "[Selangkah] " + errMsg.data : errMsg}</p>`,
                customClass: {
                    popup: "swal2-default",
                },
                confirmButtonText: 'Back to home'
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    //reload the page
                    window.location.href = '/'
                }
            });

            return;
        }
    }
}
</script>